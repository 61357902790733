export const PolicyRoutes = {
  TERMS_OF_SERVICE: '/policy/terms-of-service',
  COOKIE_POLICY: '/policy/cookie-policy',
  PRIVACY_POLICY: '/policy/privacy-policy',
}

export const _Public_PolicyRoutes = [
  PolicyRoutes.TERMS_OF_SERVICE,
  PolicyRoutes.COOKIE_POLICY,
  PolicyRoutes.PRIVACY_POLICY,
]
