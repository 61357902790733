import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type HomeFillProps = {
  color?: string
} & BoxProps

const HomeFill = ({ color, ...props }: HomeFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.3493 11.7173C23.8293 7.912 15.6213 0.822667 15.54 0.750667L14.6667 0L13.7973 0.750667C13.7147 0.821333 5.50267 7.908 0.917333 11.748C0.632198 12.0005 0.403224 12.3101 0.245211 12.6567C0.0871979 13.0033 0.00366121 13.3791 0 13.76C0 14.4672 0.280952 15.1455 0.781049 15.6456C1.28115 16.1457 1.95942 16.4267 2.66667 16.4267H4V24.4267C4 25.1339 4.28095 25.8122 4.78105 26.3123C5.28115 26.8124 5.95942 27.0933 6.66667 27.0933H22.6667C23.3739 27.0933 24.0522 26.8124 24.5523 26.3123C25.0524 25.8122 25.3333 25.1339 25.3333 24.4267V16.4267H26.6667C27.3739 16.4267 28.0522 16.1457 28.5523 15.6456C29.0524 15.1455 29.3333 14.4672 29.3333 13.76C29.3333 12.9627 28.9667 12.212 28.3493 11.7173Z'
        fill={color}
      />
    </svg>
  </Box>
)

HomeFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default HomeFill
