import { useEffect, useMemo } from 'react'

import 'dayjs/locale/pt-br'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { CompanyContext } from 'contexts/CompanyContext'

import { defaultTimeZone } from 'constants/timezones'

export const useTime = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)
  dayjs.locale('pt-br')
  dayjs.extend(customParseFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(duration)
  dayjs.extend(minMax)

  const currentCompany = CompanyContext.useSelector(state => state.currentCompany)

  const currentCompanyTimezone = useMemo(
    () => currentCompany?.CompanyDetails?.timezone || defaultTimeZone,
    [currentCompany?.CompanyDetails?.timezone]
  )

  useEffect(() => {
    if (currentCompanyTimezone) {
      dayjs.tz.setDefault(currentCompanyTimezone)
    }
  }, [currentCompanyTimezone])

  return {
    dayjs,
    currentCompanyTimezone,
  }
}
