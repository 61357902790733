import { InternalUser } from 'models/internal-user'

import { InternalCodes } from 'constants/errors'

import { onlyNumbers } from './formatters'

export const resolveErrorMessageFromRequestErr = (requestErr: any) => {
  const internalCode = requestErr?.response?.data?.internalCode || null

  if (internalCode) {
    return (
      (
        InternalCodes as Record<
          string,
          {
            internalCode: string
            message: string
          }
        >
      )[internalCode]?.message || null
    )
  }

  return null
}

export const getPhoneNumberByUser = (user: InternalUser | null | undefined) => {
  if (user?.PhoneNumbers?.length) {
    if (user?.primaryPhoneNumberIntegrationId) {
      const phoneFound = user?.PhoneNumbers?.find?.(
        p => p?.integrationId === user?.primaryPhoneNumberIntegrationId
      )

      if (phoneFound) {
        return phoneFound?.number || null
      }
    }

    return user?.PhoneNumbers?.[0]?.number || null
  }

  return null
}

export const getWhatsappUrl = (phone?: string, text?: string | void) => {
  const baseUrl = new URL('https://api.whatsapp.com/send')
  const params: string[] = []

  if (phone) {
    params.push(`phone=${onlyNumbers(phone)}`)
  }

  if (text) {
    params.push(`text=${text}`)
  }

  baseUrl.search = params.join('&')

  return baseUrl.href
}

export const getFacebookUrl = (url?: string) => {
  const baseUrl = new URL('https://www.facebook.com/sharer/sharer.php')
  const params: string[] = []

  if (url) {
    params.push(`u=${url}`)
  }

  baseUrl.search = params.join('&')

  return baseUrl.href
}

export const getTwitterUrl = (url?: string) => {
  const baseUrl = new URL('https://twitter.com/intent/tweet')
  const params: string[] = []

  if (url) {
    params.push(`url=${url}`)
  }

  baseUrl.search = params.join('&')

  return baseUrl.href
}

export const getPublicBaseUrl = () => 'https://nexpy.app'

export const getInstallPWAUrl = (qrId: string) =>
  `${getPublicBaseUrl()}/pwa?c=${encodeURIComponent(qrId)}`
