import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type SearchFillProps = {
  color?: string
} & BoxProps

const SearchFill = ({ color, ...props }: SearchFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.582 15.7233H17.4077L16.9914 15.3373C18.4986 13.6564 19.327 11.5104 19.3253 9.29103C19.3253 7.45343 18.7586 5.65711 17.6969 4.12921C16.6351 2.60131 15.126 1.41046 13.3604 0.707241C11.5948 0.00402561 9.65195 -0.179968 7.77757 0.178529C5.9032 0.537025 4.18148 1.42191 2.83013 2.72128C1.47878 4.02065 0.558506 5.67616 0.18567 7.47844C-0.187166 9.28072 0.00418664 11.1488 0.735531 12.8465C1.46687 14.5443 2.70536 15.9953 4.29438 17.0162C5.8834 18.0371 7.75157 18.582 9.66267 18.582C12.056 18.582 14.2561 17.7387 15.9508 16.3379L16.3522 16.7381V17.8674L23.785 25L26 22.8702L18.582 15.7233Z'
        fill={color}
      />
      <path
        d='M16.6988 9.16782C16.6988 12.9519 13.5642 16.0403 9.67145 16.0403C5.77867 16.0403 2.64407 12.9519 2.64407 9.16782C2.64407 5.38378 5.77867 2.29531 9.67145 2.29531C13.5642 2.29531 16.6988 5.38378 16.6988 9.16782Z'
        fill={color}
        stroke='white'
        strokeWidth='1.2'
      />
    </svg>
  </Box>
)

SearchFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default SearchFill
