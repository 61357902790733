import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'

import { SignedIn, SignedOut, SignInButton, useAuth, UserButton } from '@clerk/nextjs'
import { Box, Button, Flex, Input, Span, Text } from '@nexpy/design-system'
import { Company } from 'models/companies'
import { AccountManageRoutes } from 'routes/account-manage'
import { AuthRoutes } from 'routes/auth'
import { CompaniesRoutes } from 'routes/companies'
import { HomeRoutes } from 'routes/home'
import { MySchedulesRoutes } from 'routes/my-schedules'
import { NotificationsRoutes } from 'routes/notifications'
import { SearchRoutes } from 'routes/search'
import slugify from 'slugify'
import {
  useDebounce,
  useEventListener,
  useMediaQuery,
  useOnClickOutside,
} from 'usehooks-ts'

import GoToCompaniesButton from 'components/generics/GoToCompaniesButton'
import Loader from 'components/generics/Loader'
import TooltipButton from 'components/generics/TooltipButton'
// import Chat from 'components/icons/Chat'
import Bell from 'components/icons/Bell'
import BellFill from 'components/icons/BellFill'
import Hair from 'components/icons/Hair'
import HairFill from 'components/icons/HairFill'
import Home from 'components/icons/Home'
import HomeFill from 'components/icons/HomeFill'
import SearchFill from 'components/icons/SearchFill'
import SimpleLogo from 'components/icons/SimpleLogo'

import { LoaderContext } from 'contexts/LoaderContext'
import { OneSignalContext } from 'contexts/OneSignalContext'
import { service_SearchCompany } from 'services/clientSide/http/companies'

import { MOBILE_BREAKPOINT, TOPBAR_HEIGHT } from 'constants/layout'
import { resolveErrorMessageFromRequestErr } from 'utils/resolvers'

import { customTheme } from 'theme/theme'

const MdOutlineSearch = dynamic(() =>
  import('react-icons/md').then(mod => mod.MdOutlineSearch)
)
const MdSearch = dynamic(() => import('react-icons/md').then(mod => mod.MdSearch))

type TopBarProps = {
  isMinimalist?: boolean
  topLeftHomeOnMobile?: boolean
}

type Form = {
  search: string
}

type TopBarIconProps = {
  isActive: boolean
}

const BellIcon = ({ isActive }: TopBarIconProps) => {
  if (isActive) {
    return <BellFill w='1.9rem' />
  }

  return <Bell w='1.9rem' />
}

const HomeIcon = ({ isActive }: TopBarIconProps) => {
  if (isActive) {
    return <HomeFill w='2.3rem' />
  }

  return <Home w='2.3rem' />
}

const HairIcon = ({ isActive }: TopBarIconProps) => {
  if (isActive) {
    return <HairFill w='2.5rem' />
  }

  return <Hair w='2.5rem' />
}

const SearchIcon = ({ isActive }: TopBarIconProps) => {
  if (isActive) {
    return <SearchFill w='2.3rem' mx='0.2rem' />
  }

  return <MdOutlineSearch size='2.7rem' color={customTheme.colors.dodger} />
}

const TopBar = ({ isMinimalist, topLeftHomeOnMobile }: TopBarProps) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState<boolean>(false)
  const [searchBoxResult, setSearchBoxResult] = useState<Company[] | null>(null)

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)

  const hasSomeNotificationUnviewed = OneSignalContext.useSelector(
    state => state.hasSomeNotificationUnviewed
  )

  const router = useRouter()

  const isLoading = LoaderContext.useSelector(state => state.isLoading)

  const handleGoHome = useCallback(() => router.push(HomeRoutes.ROOT), [router])
  const handleGoNotifications = useCallback(
    () => router.push(NotificationsRoutes.ROOT),
    [router]
  )
  const handleGoToSearch = useCallback(() => router.push(SearchRoutes.ROOT), [router])
  const handleGoToMySchedules = useCallback(
    () => router.push(MySchedulesRoutes.ROOT),
    [router]
  )

  const topBarSearchForm = useForm<Form>()

  const topBarSearchFormValues = topBarSearchForm.watch()

  const debouncedTopBarSearchFormValue = useDebounce(topBarSearchFormValues.search, 200)

  const searchBoxRef = useRef<HTMLDivElement | null>(null)

  const { isLoaded } = useAuth()

  useOnClickOutside(searchBoxRef, () => {
    setIsSearchBoxOpen(false)
    setSearchBoxResult(null)
  })

  useEventListener('keydown', e => {
    if (!isSearchBoxOpen || !searchBoxResult?.length) {
      setSelectedIndex(0)

      return
    }

    if (e.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(prev => prev - 1)
    }

    if (e.key === 'ArrowDown' && selectedIndex < searchBoxResult.length - 1) {
      setSelectedIndex(prev => prev + 1)
    }

    if (e.key === 'Enter') {
      const selectedFirstResult = searchBoxResult?.[selectedIndex] || null

      if (selectedFirstResult) {
        setIsSearchBoxOpen(false)
        setSelectedIndex(0)

        if (selectedFirstResult.username) {
          router.push(
            CompaniesRoutes.PROFILE({
              username: selectedFirstResult.username,
            })
          )
        }
      }
    }
  })

  const getSlug = useCallback((q: string) => {
    const query = slugify(q, {
      lower: true,
      replacement: '_',
      strict: true,
      trim: true,
    })

    return query
  }, [])

  const resolveSearch = useCallback(
    async (q: string) => {
      const query = getSlug(q)

      if (!query) {
        return
      }

      try {
        const { data } = await service_SearchCompany(query)

        setIsSearchBoxOpen(true)
        setSearchBoxResult(data)
      } catch (requestErr) {
        toast.error(
          resolveErrorMessageFromRequestErr(requestErr) ||
            'Algo deu errado nesta pesquisa. Verifique sua conexão e tente novamente.',
          {
            autoClose: false,
          }
        )
      } finally {
        setIsSearchLoading(false)
      }
    },
    [getSlug]
  )

  useEffect(() => {
    if (debouncedTopBarSearchFormValue) {
      resolveSearch(debouncedTopBarSearchFormValue)
    } else {
      setIsSearchBoxOpen(false)
      setSearchBoxResult(null)
    }
  }, [debouncedTopBarSearchFormValue, resolveSearch])

  useEffect(() => {
    if (topBarSearchFormValues.search) {
      setIsSearchLoading(true)
      setIsSearchBoxOpen(true)
    } else {
      setIsSearchBoxOpen(false)
    }
  }, [topBarSearchFormValues.search])

  return (
    <Box position='fixed' top='0' left='0' right='0' zIndex='10'>
      <Flex
        h={TOPBAR_HEIGHT}
        justifyContent='space-between'
        alignItems='center'
        px='3rem'
        boxShadow='0px 2px 4px rgba(0, 0, 0, 0.1)'
        position='relative'
        bg='white'
        as='header'
      >
        <Flex alignItems='center'>
          <Button
            variant='ghost'
            type='button'
            onClick={handleGoHome}
            aria-label='Home'
            gap='2rem'
          >
            <SimpleLogo
              w='5.2rem'
              display={topLeftHomeOnMobile ? { _: 'none', md: 'unset' } : undefined}
            />

            {topLeftHomeOnMobile ? <Home w='2.3rem' display={{ md: 'none' }} /> : null}
          </Button>
          <Loader
            ml={topLeftHomeOnMobile ? { _: '1.2rem', md: '2.2rem' } : '2.2rem'}
            transition='all 0.2s ease'
            opacity={isLoading ? '1' : '0'}
          />
        </Flex>
        <Flex
          position='absolute'
          top={`calc(${TOPBAR_HEIGHT} / 2 - 3.6rem / 2)`}
          left='calc(50% - 35rem / 2)'
          display={isMinimalist ? 'none' : { _: 'none', lg: 'flex' }}
        >
          <Flex
            borderRadius='0.6rem'
            bg='white'
            borderColor={{ _: 'platinum', '&:has(input:focus)': 'oldSilver' }}
            borderWidth='1px'
            borderStyle='solid'
            transition='border-color 0.15s ease'
            alignItems='center'
            h='3.6rem'
            px='0.8rem'
            w='35rem'
            position='relative'
            ref={searchBoxRef}
          >
            <Span>
              <MdSearch color={customTheme.colors.oldSilver} />
            </Span>
            <Input
              h='3.4rem'
              type='search'
              w='100%'
              {...topBarSearchForm.register('search')}
            />

            {isSearchBoxOpen ? (
              <Box
                position='absolute'
                top='4.2rem'
                left='0'
                right='0'
                bg='white'
                borderRadius='0.4rem'
                borderColor='platinum'
                borderWidth='1px'
                borderStyle='solid'
              >
                {isSearchLoading && getSlug(topBarSearchFormValues.search) ? (
                  <Skeleton height='4rem' />
                ) : null}
                {isSearchLoading && !getSlug(topBarSearchFormValues.search) ? (
                  <Text px='1rem' variant='caption'>
                    Digite algo para pesquisar
                  </Text>
                ) : null}

                {!isSearchLoading && !searchBoxResult?.length ? (
                  <Flex alignItems='center' h='4rem'>
                    <Text color='balticSea' w='100%' textAlign='center'>
                      Não há resultados
                    </Text>
                  </Flex>
                ) : null}

                {!isSearchLoading && searchBoxResult?.length ? (
                  <Box>
                    {searchBoxResult.map((result, index) => (
                      <Button
                        key={result.id}
                        variant='ghost'
                        w='100%'
                        h='4rem'
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        px='1rem'
                        bg={selectedIndex === index ? 'lavenderIndigo' : 'white'}
                        color={selectedIndex === index ? 'white' : 'lavenderIndigo'}
                        onClick={() => {
                          setIsSearchBoxOpen(false)

                          if (result.username) {
                            router.push(
                              CompaniesRoutes.PROFILE({
                                username: result.username,
                              })
                            )
                          }
                        }}
                      >
                        {result.name}
                      </Button>
                    ))}
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Flex>
        </Flex>
        <Flex alignItems='center' gap={isMinimalist ? '1.4rem' : '2rem'}>
          <GoToCompaniesButton isMinimalist={isMinimalist} />

          <Flex alignItems='center' gap='2rem' display={{ _: 'none', md: 'flex' }}>
            <TooltipButton
              label='Página inicial'
              tooltipId='home-button-tooltip'
              onClick={handleGoHome}
              display={isMinimalist ? 'none' : undefined}
            >
              <HomeIcon isActive={router.pathname === '/'} />
            </TooltipButton>

            {/* <SignedIn>
              <TooltipButton
                label='Mensagens'
                tooltipId='chat-button-tooltip'
                display={isMinimalist ? 'none' : undefined}
              >
                <Chat w='2.2rem' />
              </TooltipButton>
            </SignedIn> */}

            <TooltipButton
              label='Meus Agendamentos'
              tooltipId='my-schedules-button-tooltip'
              onClick={handleGoToMySchedules}
              display={isMinimalist ? 'none' : undefined}
            >
              <HairIcon isActive={router.pathname.startsWith(MySchedulesRoutes.ROOT)} />
            </TooltipButton>

            <TooltipButton
              label='Pesquisar'
              tooltipId='search-button-tooltip'
              onClick={handleGoToSearch}
              display={isMinimalist ? 'none' : { lg: 'none' }}
            >
              <SearchIcon isActive={router.pathname.startsWith(SearchRoutes.ROOT)} />
            </TooltipButton>
          </Flex>

          <Flex alignItems='center' gap='2rem' minWidth='4.2rem'>
            <SignedIn>
              <TooltipButton
                label='Notificações'
                tooltipId='bell-button-tooltip'
                onClick={handleGoNotifications}
                display={isMinimalist ? 'none' : undefined}
                position='relative'
                disableTooltip={isMobile}
              >
                <BellIcon
                  isActive={router.pathname.startsWith(NotificationsRoutes.ROOT)}
                />

                {hasSomeNotificationUnviewed ? (
                  <Box
                    position='absolute'
                    top='0px'
                    right='-2px'
                    borderRadius='50%'
                    bg='red'
                    w='1.2rem'
                    h='1.2rem'
                  />
                ) : null}
              </TooltipButton>
            </SignedIn>

            {!isLoaded ? <Skeleton circle width='4.2rem' height='4.2rem' /> : null}

            <SignedIn>
              <Box minWidth='4.2rem'>
                <UserButton
                  userProfileMode='navigation'
                  userProfileUrl={AccountManageRoutes.PROFILE}
                  afterSignOutUrl={HomeRoutes.ROOT}
                  signInUrl={AuthRoutes.LOGIN}
                  appearance={{
                    variables: {
                      fontSize: '2rem',
                    },
                    elements: {
                      userPreviewMainIdentifier: {
                        fontSize: '1.7rem',
                      },
                    },
                  }}
                />
              </Box>
            </SignedIn>
            <SignedOut>
              <SignInButton mode='redirect'>
                <Button type='button'>Login</Button>
              </SignInButton>
            </SignedOut>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

TopBar.defaultProps = {
  isMinimalist: false,
  topLeftHomeOnMobile: false,
}

export default TopBar
