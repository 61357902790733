import { Box, BoxProps } from '@nexpy/design-system'

type LoaderProps = {
  size?: string
} & BoxProps

const Loader = ({ size, color, ...props }: LoaderProps) => (
  <Box w={size} h={size} {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
    >
      <circle cx='84' cy='50' r='10' fill='#356da9'>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='0.43859649122807015s'
          calcMode='spline'
          keyTimes='0;1'
          values='10;0'
          keySplines='0 0.5 0.5 1'
          begin='0s'
        />
        <animate
          attributeName='fill'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='discrete'
          keyTimes='0;0.25;0.5;0.75;1'
          values='#356da9;#4ac8ff;#5c50ff;#9855ff;#356da9'
          begin='0s'
        />
      </circle>
      <circle cx='16' cy='50' r='10' fill='#356da9'>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='0s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='0s'
        />
      </circle>
      <circle cx='50' cy='50' r='10' fill='#9855ff'>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.43859649122807015s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.43859649122807015s'
        />
      </circle>
      <circle cx='84' cy='50' r='10' fill='#5c50ff'>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.8771929824561403s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-0.8771929824561403s'
        />
      </circle>
      <circle cx='16' cy='50' r='10' fill='#4ac8ff'>
        <animate
          attributeName='r'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='0;0;10;10;10'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-1.3157894736842104s'
        />
        <animate
          attributeName='cx'
          repeatCount='indefinite'
          dur='1.7543859649122806s'
          calcMode='spline'
          keyTimes='0;0.25;0.5;0.75;1'
          values='16;16;16;50;84'
          keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
          begin='-1.3157894736842104s'
        />
      </circle>
    </svg>
  </Box>
)

Loader.defaultProps = {
  size: '4rem',
}

export default Loader
