import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type HairFillProps = {
  color?: string
} & BoxProps

const HairFill = ({ color, ...props }: HairFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.9901 4.06321C15.1061 1.55121 12.7101 0.163208 10.1901 0.163208C7.67008 0.163208 5.27408 1.55121 3.39008 4.06321C1.97308 5.95221 0.264078 8.50321 0.0280777 11.1872C-0.0929223 12.5712 0.175078 13.9882 1.04708 15.3372C1.90808 16.6692 3.31508 17.8682 5.36208 18.9172C6.62108 19.5622 7.99208 18.6972 8.17108 17.4342C7.74548 17.1477 7.35542 16.8117 7.00908 16.4332C5.99608 15.3282 5.18908 13.7102 5.18908 11.6632C5.18908 8.23521 6.95908 5.92821 9.08908 5.31921C9.37108 5.23921 9.68908 5.45121 9.72308 5.74321C9.86421 6.9522 10.4415 8.06819 11.3467 8.88191C12.252 9.69564 13.4229 10.1512 14.6401 10.1632C15.3141 10.1692 15.1901 11.2232 15.1901 11.6632C15.1901 13.7102 14.3841 15.3282 13.3701 16.4332C13.0081 16.8292 12.6101 17.1672 12.2061 17.4362C12.3661 18.7042 13.7241 19.5762 14.9841 18.9672C17.1491 17.9232 18.6271 16.7242 19.5221 15.3822C20.4291 14.0202 20.6851 12.5852 20.5291 11.1882C20.2291 8.49821 18.3921 5.93121 16.9901 4.06321Z'
        fill={color}
      />
    </svg>
  </Box>
)

HairFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default HairFill
