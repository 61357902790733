import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type BellProps = {
  color?: string
} & BoxProps

const Bell = ({ color, ...props }: BellProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.52796 2.33067C9.72717 1.83829 10.0689 1.41662 10.5093 1.1197C10.9497 0.822791 11.4688 0.664169 12 0.664169C12.5311 0.664169 13.0502 0.822791 13.4906 1.1197C13.931 1.41662 14.2727 1.83829 14.472 2.33067C16.4436 2.87292 18.1828 4.04741 19.4223 5.67379C20.6618 7.30016 21.3332 9.28846 21.3333 11.3333V17.596L23.776 21.26C23.9099 21.4608 23.9869 21.6942 23.9986 21.9353C24.0103 22.1764 23.9563 22.4162 23.8425 22.629C23.7286 22.8419 23.5591 23.0198 23.352 23.1438C23.1449 23.2679 22.908 23.3334 22.6666 23.3333H16.62C16.4594 24.4441 15.904 25.4599 15.0556 26.1946C14.2071 26.9293 13.1223 27.3336 12 27.3336C10.8776 27.3336 9.79283 26.9293 8.94435 26.1946C8.09587 25.4599 7.54049 24.4441 7.37995 23.3333H1.33329C1.0919 23.3334 0.855026 23.2679 0.647944 23.1438C0.440861 23.0198 0.271336 22.8419 0.157457 22.629C0.0435772 22.4162 -0.0103851 22.1764 0.00132632 21.9353C0.0130378 21.6942 0.0899838 21.4608 0.223956 21.26L2.66662 17.596V11.3333C2.66662 7.03467 5.57329 3.41334 9.52796 2.33067ZM10.1146 23.3333C10.2523 23.7235 10.5077 24.0614 10.8455 24.3004C11.1832 24.5394 11.5868 24.6678 12.0006 24.6678C12.4144 24.6678 12.818 24.5394 13.1558 24.3004C13.4936 24.0614 13.7489 23.7235 13.8866 23.3333H10.1133H10.1146ZM12 4.66667C10.2318 4.66667 8.53615 5.36905 7.28591 6.61929C6.03567 7.86954 5.33329 9.56523 5.33329 11.3333V18C5.33335 18.2634 5.2554 18.5209 5.10929 18.74L3.82529 20.6667H20.1733L18.8893 18.74C18.7436 18.5207 18.6662 18.2632 18.6666 18V11.3333C18.6666 9.56523 17.9642 7.86954 16.714 6.61929C15.4638 5.36905 13.7681 4.66667 12 4.66667Z'
        fill={color}
      />
    </svg>
  </Box>
)

Bell.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Bell
