export const InternalCodes = {
  COMPANY_NAME_IN_USE_BY_CURRENT_USER: {
    internalCode: 'COMPANY_NAME_IN_USE_BY_CURRENT_USER',
    message: 'Você já usou este nome em uma empresa sua. Tente outro!',
  },
  COMPANY_USERNAME_IN_USE: {
    internalCode: 'COMPANY_USERNAME_IN_USE',
    message: 'Este nome de usuário empresarial já está em uso. Tente outro!',
  },
  COMPANY_USERNAME_NOT_ALLOWED: {
    internalCode: 'COMPANY_USERNAME_NOT_ALLOWED',
    message:
      "Você não pode utilizar este nome de usuário. Tente outro diferente. Se for o caso, não inclua 'nexpy' ao seu nome de usuário.",
  },
  COMPANY_IS_NOT_ACTIVE: {
    internalCode: 'COMPANY_IS_NOT_ACTIVE',
    message: 'A sua empresa não está ativa para realizar esta ação.',
  },
  NEED_ADMIN_ACCESS: {
    internalCode: 'NEED_ADMIN_ACCESS',
    message:
      'Você precisa estar logado em uma conta de administrador para realizar esta ação.',
  },
  DOES_NOT_OWN_THE_COMPANY: {
    internalCode: 'DOES_NOT_OWN_THE_COMPANY',
    message: 'Você não possui as permissões necessárias para gerenciar esta empresa.',
  },
  THE_COMPANY_NOT_OPEN_SELECTED_HOURS: {
    internalCode: 'THE_COMPANY_NOT_OPEN_SELECTED_HOURS',
    message: 'A empresa não está aberta neste horário. Tente outro.',
  },
  THE_TIME_CHOSEN_CONFLICTS_WITH_ONE_ALREADY_EXISTING: {
    internalCode: 'THE_TIME_CHOSEN_CONFLICTS_WITH_ONE_ALREADY_EXISTING',
    message:
      'Não foi possível agendar no momento selecionado pois outro usuário preencheu o mesmo horário. Por favor, escolha outro horário e tente novamente.',
  },
  SCHEDULE_IN_ADVANCE: {
    internalCode: 'SCHEDULE_IN_ADVANCE',
    message:
      'Você não pode agendar com menos de três horas de antecedência ao horário escolhido. Escolha outro horário após esse período.',
  },
  CANCEL_SCHEDULE_IN_ADVANCE: {
    internalCode: 'CANCEL_SCHEDULE_IN_ADVANCE',
    message:
      'Você não pode cancelar um agendamento com menos de duas horas de antecedência. Por favor, entre em contato com a empresa para cancelar.',
  },
  SCHEDULING_IN_PAST: {
    internalCode: 'SCHEDULING_IN_PAST',
    message: 'Você não pode criar em um momento que já passou.',
  },
  EMPLOYEE_NOT_ACTIVE_OR_NOT_SCHEDULING_ONLINE: {
    internalCode: 'EMPLOYEE_NOT_ACTIVE_OR_NOT_SCHEDULING_ONLINE',
    message:
      'O funcionário selecionado não está mais ativo ou disponível para receber agendamentos. Tente outro.',
  },
  SERVICE_NOT_ACTIVE_OR_NOT_SCHEDULING_ONLINE: {
    internalCode: 'SERVICE_NOT_ACTIVE_OR_NOT_SCHEDULING_ONLINE',
    message:
      'O serviço selecionado não está mais ativo ou disponível para receber agendamentos. Tente outro.',
  },
  MAX_SCHEDULES_EXCEEDED: {
    internalCode: 'MAX_SCHEDULES_EXCEEDED',
    message:
      'Você excedeu o limite de agendamentos ativos futuros que você pode possuir.',
  },
  TRIAL_ALREADY_USED: {
    internalCode: 'TRIAL_ALREADY_USED',
    message: 'Você já utilizou o período de testes.',
  },
  NO_SIGNATURE_ACTIVE: {
    internalCode: 'NO_SIGNATURE_ACTIVE',
    message:
      'A sua assinatura do Nexpy Premium está inativa. Ative-a para realizar esta ação.',
  },
} as const
