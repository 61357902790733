import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type HomeProps = {
  color?: string
} & BoxProps

const Home = ({ color, ...props }: HomeProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.6827 11.9573C24.1627 8.152 15.9547 1.06267 15.8734 0.990672L15 0.240005L14.1307 0.990672C14.048 1.06134 5.83604 8.148 1.25071 11.988C0.965572 12.2405 0.736598 12.5501 0.578585 12.8967C0.420572 13.2433 0.337035 13.6191 0.333374 14C0.333374 14.7072 0.614326 15.3855 1.11442 15.8856C1.61452 16.3857 2.2928 16.6667 3.00004 16.6667H4.33337V24.6667C4.33337 25.3739 4.61433 26.0522 5.11442 26.5523C5.61452 27.0524 6.2928 27.3333 7.00004 27.3333H23C23.7073 27.3333 24.3856 27.0524 24.8857 26.5523C25.3858 26.0522 25.6667 25.3739 25.6667 24.6667V16.6667H27C27.7073 16.6667 28.3856 16.3857 28.8857 15.8856C29.3857 15.3855 29.6667 14.7072 29.6667 14C29.6667 13.2027 29.3 12.452 28.6827 11.9573ZM23 14L23.0027 24.6667H19H11H7.00004V14H2.99871C6.68537 10.9173 12.752 5.69734 15 3.76001C17.248 5.69734 23.312 10.916 27 14.0013L23 14Z'
        fill={color}
      />
    </svg>
  </Box>
)

Home.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Home
