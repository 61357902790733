import Link from 'next/link'

import { Flex, FlexProps, Text } from '@nexpy/design-system'
import { useTime } from 'hooks/useTime'
import { PolicyRoutes } from 'routes/policy'

import Container from 'components/generics/Container'

import { SUPPORT_FORM_URL } from 'constants/google-forms'

const Footer = ({ ...props }: FlexProps) => {
  const { dayjs } = useTime()

  return (
    <Container
      className='nexpy-footer'
      mt='2.4rem'
      mb={{ _: '7.2rem', md: '1.2rem' }}
      {...props}
    >
      <Flex flexDirection='column' alignItems='center' justifyContent='center'>
        <Text color='silverChalice' variant='caption' textAlign='center'>
          &copy; Nexpy {dayjs().format('YYYY')} Todos os direitos reservados.
        </Text>

        <Flex gap='1.2rem' mb='0.8rem'>
          <Link className='footer-link' href={PolicyRoutes.TERMS_OF_SERVICE}>
            Termos de Serviço
          </Link>
          <Link className='footer-link' href={PolicyRoutes.PRIVACY_POLICY}>
            Privacidade
          </Link>
          <Link className='footer-link' href={PolicyRoutes.COOKIE_POLICY}>
            Cookies
          </Link>
          <Link
            href={SUPPORT_FORM_URL}
            rel='noopener noreferrer'
            target='_blank'
            className='footer-link'
          >
            Suporte
          </Link>
          <Link
            href='https://status.nexpy.app'
            rel='noopener noreferrer'
            target='_blank'
            className='footer-link'
          >
            Status
          </Link>
        </Flex>

        {/* <Text color='silverChalice' fontSize='1rem !important' textAlign='center'>
          CNPJ 43.771.892/0001-37
        </Text> */}
      </Flex>

      <style global jsx>
        {`
          .footer-link {
            text-decoration: none;
            color: #adadad;
            font-size: 1rem;
            text-align: center;
            pointer-events: auto;
          }
        `}
      </style>
    </Container>
  )
}

export default Footer
