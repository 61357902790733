import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type SimpleLogoProps = {
  color?: string
} & BoxProps

const SimpleLogo = ({ color, ...props }: SimpleLogoProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 64 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32 37.4815C26.1215 37.4815 21.3333 35.0679 21.3333 30.1934C21.3333 25.2716 26.1215 21.2963 32 21.2963C37.8785 21.2963 42.6667 25.2716 42.6667 30.1934C42.6667 35.0679 37.8785 37.4815 32 37.4815ZM32 24.8457C33.9437 24.8457 35.5556 25.5556 35.5556 27.2593C35.5556 28.963 33.28 30.0988 32 30.6667C30.4356 30.0988 28.16 28.963 28.16 27.2593C28.16 25.3189 30.0563 24.8457 32 24.8457ZM31.8578 46C40.96 46 45.7956 43.7284 50.6311 37.4815C53.3333 33.6955 54.9452 27.9218 54.9452 22.9527V22.6687C60.1126 21.5802 64 16.9897 64 11.4527C64 5.15844 58.8326 0 52.5274 0C49.2563 0 46.2222 1.37243 44.2311 3.54938C40.5742 1.23133 36.3319 0.000349779 32 0.000349779C27.6681 0.000349779 23.4258 1.23133 19.7689 3.54938C17.7778 1.37243 14.7437 0 11.4726 0C5.16741 0 0 5.15844 0 11.4527C0 16.9424 3.88741 21.5329 9.00741 22.6687V22.9527C9.00741 27.9218 10.0456 33.8153 12.8 37.4815C17.4933 43.7284 22.7556 46 31.8578 46Z'
        fill={color}
      />
    </svg>
  </Box>
)

SimpleLogo.defaultProps = {
  color: customTheme.colors.lavenderIndigo,
}

export default SimpleLogo
