import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type BellFillProps = {
  color?: string
} & BoxProps

const BellFill = ({ color, ...props }: BellFillProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5282 1.6665C9.72742 1.17412 10.0691 0.752446 10.5096 0.455534C10.95 0.158622 11.469 0 12.0002 0C12.5314 0 13.0504 0.158622 13.4908 0.455534C13.9313 0.752446 14.273 1.17412 14.4722 1.6665C16.4439 2.20875 18.183 3.38325 19.4226 5.00962C20.6621 6.63599 21.3334 8.62429 21.3335 10.6692V16.9318L23.7762 20.5958C23.9102 20.7966 23.9871 21.03 23.9988 21.2711C24.0105 21.5123 23.9566 21.752 23.8427 21.9649C23.7288 22.1777 23.5593 22.3556 23.3522 22.4797C23.1451 22.6037 22.9083 22.6692 22.6669 22.6692H16.6202C16.4597 23.78 15.9043 24.7958 15.0558 25.5304C14.2073 26.2651 13.1225 26.6695 12.0002 26.6695C10.8779 26.6695 9.79308 26.2651 8.9446 25.5304C8.09612 24.7958 7.54074 23.78 7.3802 22.6692H1.33353C1.09214 22.6692 0.85527 22.6037 0.648188 22.4797C0.441105 22.3556 0.271581 22.1777 0.157701 21.9649C0.0438213 21.752 -0.010141 21.5123 0.00157047 21.2711C0.0132819 21.03 0.0902279 20.7966 0.2242 20.5958L2.66687 16.9318V10.6692C2.66687 6.3705 5.57353 2.74917 9.5282 1.6665Z'
        fill={color}
      />
    </svg>
  </Box>
)

BellFill.defaultProps = {
  color: customTheme.colors.dodger,
}

export default BellFill
