import { Box, BoxProps } from '@nexpy/design-system'

import { customTheme } from 'theme/theme'

type HairProps = {
  color?: string
} & BoxProps

const Hair = ({ color, ...props }: HairProps) => (
  <Box {...props}>
    <svg width='100%' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.02941 18.0511C7.61041 19.0231 6.44441 19.5841 5.36141 19.0301C3.31541 17.9811 1.90741 16.7821 1.04641 15.4501C0.175411 14.1011 -0.0935894 12.6841 0.0274106 11.3001C0.263411 8.61712 1.97341 6.06612 3.38941 4.17612C5.27341 1.66412 7.66941 0.276123 10.1894 0.276123C12.7094 0.276123 15.1054 1.66412 16.9894 4.17612C18.3904 6.04412 20.2294 8.61012 20.5294 11.3011C20.6844 12.6981 20.4294 14.1331 19.5214 15.4951C18.6274 16.8371 17.1484 18.0351 14.9834 19.0801C13.9004 19.6031 12.7454 19.0331 12.3404 18.0571C11.6184 18.5111 10.8624 18.7761 10.1904 18.7761C9.51441 18.7761 8.75341 18.5081 8.02841 18.0511H8.02941ZM4.98941 5.37612C6.60541 3.22212 8.45941 2.27612 10.1894 2.27612C11.9194 2.27612 13.7734 3.22212 15.3894 5.37612C16.8434 7.31412 18.3114 9.45812 18.5414 11.5221C18.6504 12.5021 18.4764 13.4561 17.8574 14.3861C17.2314 15.3241 16.1064 16.3061 14.1894 17.2431V16.3421C15.0554 15.1921 15.6894 13.6501 15.6894 11.7761C15.6894 11.3931 15.6694 11.0211 15.6284 10.6631C15.6008 10.4207 15.4856 10.1967 15.3044 10.0333C15.1231 9.86994 14.8884 9.77847 14.6444 9.77612C13.5492 9.76507 12.4957 9.35509 11.6811 8.62299C10.8665 7.89089 10.3469 6.8869 10.2194 5.79912C10.2012 5.65572 10.1524 5.5179 10.0763 5.39497C10.0003 5.27205 9.89869 5.16688 9.77848 5.08659C9.65827 5.00629 9.52223 4.95274 9.37955 4.92954C9.23686 4.90634 9.09086 4.91404 8.95141 4.95212C6.55741 5.63612 4.68941 8.18112 4.68941 11.7761C4.68941 13.6501 5.32341 15.1921 6.18941 16.3421V17.2061C4.40241 16.2781 3.33341 15.3021 2.72641 14.3631C2.11941 13.4251 1.93341 12.4611 2.02041 11.4761C2.20041 9.42112 3.55941 7.28012 4.98941 5.37612ZM6.68941 11.7761C6.68941 9.55412 7.53941 8.10212 8.53241 7.36412C8.91454 8.48862 9.59709 9.48711 10.5061 10.2514C11.4152 11.0157 12.516 11.5167 13.6894 11.7001C13.7014 13.1081 13.2214 14.4891 12.2644 15.5321C11.4344 16.4391 10.5564 16.7761 10.1894 16.7761C9.82341 16.7761 8.94641 16.4391 8.11441 15.5321C7.32741 14.6731 6.68941 13.4091 6.68941 11.7761Z'
        fill={color}
      />
    </svg>
  </Box>
)

Hair.defaultProps = {
  color: customTheme.colors.dodger,
}

export default Hair
