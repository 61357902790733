import React, { ReactNode } from 'react'

import { Flex, FlexProps } from '@nexpy/design-system'

import { DEFAULT_LAYOUT_MAX_WIDTH } from 'constants/layout'

type ContainerProps = {
  children: ReactNode
  maxContainerWidth?: string | number | undefined
} & FlexProps

const Container = ({ children, maxContainerWidth, ...props }: ContainerProps) => (
  <Flex justifyContent='center' alignItems='center' flexDirection='column'>
    <Flex
      maxWidth={maxContainerWidth || DEFAULT_LAYOUT_MAX_WIDTH}
      flexDirection='column'
      w='100%'
      {...props}
    >
      {children}
    </Flex>
  </Flex>
)

Container.defaultProps = {
  maxContainerWidth: undefined,
}

export default Container
