import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Flex, Select, Text } from '@nexpy/design-system'
import { ConsoleRoutes } from 'routes/console'

import { CompanyContext } from 'contexts/CompanyContext'

import { customTheme } from 'theme/theme'

const MdDashboard = dynamic(() => import('react-icons/md').then(mod => mod.MdDashboard))

type GoToCompaniesButtonProps = {
  isMinimalist?: boolean
}

type CurrentCompanySelectForm = {
  currentCompany: string
}

const GoToCompaniesButton = ({ isMinimalist }: GoToCompaniesButtonProps) => {
  const router = useRouter()

  const userCompanies = CompanyContext.useSelector(state => state.userCompanies)
  const currentCompany = CompanyContext.useSelector(state => state.currentCompany)
  const setCurrentCompany = CompanyContext.useSelector(state => state.setCurrentCompany)

  const goToCompanies = useCallback(() => {
    router.push(ConsoleRoutes.CALENDAR)
  }, [router])

  const userInConsole = router.pathname.startsWith(ConsoleRoutes.ROOT)

  const { control, setValue, watch } = useForm<CurrentCompanySelectForm>()

  const formValues = watch()

  const userCompaniesOptions = useMemo(
    () =>
      userCompanies?.map(company => ({
        label: company.name,
        value: company.id,
      })) || [],
    [userCompanies]
  )

  useEffect(() => {
    if (currentCompany && formValues.currentCompany !== currentCompany.id) {
      setValue('currentCompany', currentCompany.id)
    }
  }, [currentCompany, formValues.currentCompany, setCurrentCompany, setValue])

  useEffect(() => {
    if (!userCompanies?.length) {
      return
    }

    router.prefetch(ConsoleRoutes.CALENDAR)
  }, [router, userCompanies?.length])

  if (!userCompanies?.length) {
    return null
  }

  if (userCompanies.length > 1 && userInConsole) {
    return (
      <Flex
        mt='1.7rem'
        mr='-2.7rem'
        transform='scale(0.75)'
        justifyContent='center'
        alignItems='center'
      >
        <Select
          control={control}
          label=' '
          options={userCompaniesOptions}
          name='currentCompany'
          w={{ _: '18rem', md: '24rem' }}
          onChange={currentValue => {
            if (currentValue?.value) {
              setCurrentCompany(currentValue.value)
            }
          }}
          isClearable={false}
        />
      </Flex>
    )
  }

  return (
    <Button
      textTransform='unset'
      display={userInConsole ? 'none' : 'flex'}
      justifyContent='space-between'
      alignItems='center'
      gap='0.8rem'
      borderRadius='5rem'
      onClick={goToCompanies}
      {...(isMinimalist
        ? {
            variant: 'ghost',
            color: customTheme.colors.balticSea,
          }
        : {
            p: '0.4rem 1.2rem 0.6rem 1.2rem',
          })}
    >
      <MdDashboard size='1.8rem' />

      {userCompanies.length > 1 ? (
        <Text
          display={{ _: 'none', xl: 'initial' }}
          fontSize='1.5rem !important'
          {...(isMinimalist
            ? {
                color: customTheme.colors.balticSea,
              }
            : {
                color: 'white',
              })}
        >
          Minhas Empresas
        </Text>
      ) : (
        <Text
          display={{ _: 'none', xl: 'initial' }}
          fontSize='1.5rem !important'
          {...(isMinimalist
            ? {
                color: customTheme.colors.balticSea,
              }
            : {
                color: 'white',
              })}
        >
          Minha Empresa
        </Text>
      )}
    </Button>
  )
}

GoToCompaniesButton.defaultProps = {
  isMinimalist: false,
}

export default GoToCompaniesButton
