import { ComponentProps, ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

import { Button, ButtonProps, Portal } from '@nexpy/design-system'

type TooltipButtonProps = {
  children: ReactNode
  label: string
  tooltipId: string
  tooltip?: Omit<ComponentProps<typeof Tooltip>, 'id'>
  usePortal?: boolean
  disableTooltip?: boolean
} & ButtonProps

const TooltipButton = ({
  children,
  label,
  tooltipId,
  tooltip,
  usePortal,
  disableTooltip,
  ...props
}: TooltipButtonProps) => (
  <>
    {usePortal ? (
      <Portal>
        <Tooltip id={tooltipId} {...tooltip} />
      </Portal>
    ) : (
      <Tooltip id={tooltipId} {...tooltip} />
    )}

    <Button
      h='3rem'
      variant='ghost'
      {...props}
      aria-label={label}
      data-tooltip-id={tooltipId}
      data-tooltip-content={disableTooltip ? undefined : label}
    >
      {children}
    </Button>
  </>
)

TooltipButton.defaultProps = {
  tooltip: undefined,
  usePortal: undefined,
  disableTooltip: undefined,
}

export default TooltipButton
